/* eslint-disable class-methods-use-this */
import Service from './Service';

class UserService extends Service {
  constructor() {
    super('users');
  }

  getFieldsTableUsers() {
    return [
      {
        key: 'name',
        label: 'Nome',
        tdClass: 'text-nowrap text-capitalize',
        thClass: 'text-nowrap',
      },
      {
        key: 'email',
        label: 'Email',
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
      },
      {
        key: 'type',
        label: 'Tipo',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'actions',
        label: 'Ações',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
    ];
  }
}

const userService = new UserService();
export default userService;
