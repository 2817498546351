<template>
  <div>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-user"></i> Usuários Cadastrados
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-button variant="primary" v-b-modal.new-user>
          <i class="fas fa-plus"></i> Adicionar novo usuário
        </b-button>
      </div>

      <div class="w-100 p-3">
        <b-table
        responsive
        :busy="load"
        :items="users"
        :fields="columsTableUsers"
        >
        <template #table-busy>
          <div class="text-center text-success my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
          v-b-tooltip.hover
          size="sm"
          title="Editar"
          class="mr-2"
          @click="getUser(data.item.id)"
          variant="warning">
            <i class="fas fa-pen-square"></i>
          </b-button>
        </template>
        </b-table>
      </div>
    </b-row>

    <b-modal
    ref="add-user"
    cancel-title="Fechar"
    id="new-user"
    @hide="reset"
    title="Cadastrar novo usuário">
      <b-row>
        <b-col lg="12">
          <b-form-group label="Permissão">
            <b-form-select
            :options="typeUsers"
            v-model="payload.type" />
          </b-form-group>
        </b-col>
        <b-col lg="12" v-if="payload.type === 'public'">
          <b-form-group label="Nome">
            <b-form-select
            @change="getAgent"
            :options="agents"
            v-model="payload.agent_id"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12" v-else>
          <b-form-group label="Nome">
            <b-form-input
            :class="{ 'form-group--error': $v.payload.name.$error }"
            v-model="$v.payload.name.$model"
            :state="$v.payload.name.$dirty ?
            !$v.payload.name.$error : null">
              </b-form-input>
          </b-form-group>
        </b-col>

        <b-col lg="12">
          <b-form-group label="Email">
            <b-form-input
            :class="{ 'form-group--error': $v.payload.email.$error }"
            v-model="$v.payload.email.$model"
            :state="$v.payload.email.$dirty ?
            !$v.payload.email.$error : null"
            />
          </b-form-group>
        </b-col>

        <b-col lg="12">
          <b-form-group label="Senha">
            <b-form-input
            :class="{ 'form-group--error': $v.payload.password.$error }"
            v-model="$v.payload.password.$model"
            :state="$v.payload.password.$dirty ?
            !$v.payload.password.$error : null"/>
          </b-form-group>
        </b-col>
      </b-row>
       <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              :disabled="spinner"
              variant="primary"
              size="sm"
              class="float-right"
              @click="save"
            >
            <b-spinner small v-show="spinner"></b-spinner>
              Salvar
            </b-button>
          </div>
        </template>

    </b-modal>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';
import UserService from '@/Services/UserService';
import AgentService from '@/Services/AgentService';

export default {
  data() {
    return {
      columsTableUsers: UserService.getFieldsTableUsers(),
      users: [],
      load: true,
      spinner: false,
      agents: [
        {
          text: 'Selecione',
          value: '',
        },
      ],
      typeUsers: [
        {
          text: 'Administrador',
          value: 'admin',
        },
        {
          text: 'Usuário comum',
          value: 'public',
        },
      ],
      payload: {
        id: '',
        agent_id: '',
        name: '',
        email: '',
        password: '',
        type: 'public',
      },
    };
  },

  validations: {
    payload: {
      name: { required },
      email: { required, email },
      password: { required },
    },
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  methods: {
    async getAll() {
      this.load = true;

      const { data } = await UserService.getList();
      this.users = data.map((item) => ({
        id: item.id,
        name: item.name,
        email: item.email,
        type: item.type === 'public' ? 'Agente' : 'Administrador',
      }));

      this.load = false;
    },

    async getAgent() {
      const { data } = await AgentService.get(this.payload.agent_id);
      this.payload.name = data.name;
      this.payload.email = data.email;
    },

    clearData() {
      this.payload.name = '';
      this.payload.email = '';
      this.payload.agent_id = '';
    },

    async getAgents() {
      const { data } = await AgentService.getList();
      data.forEach((item) => {
        this.agents.push({
          text: item.name,
          value: item.id,
        });
      });
    },

    async getUser(id) {
      const { data } = await UserService.get(id);
      this.payload = { ...data };
      if (data.type === 'public') this.payload.agent_id = data.agent.id;
      this.$nextTick(() => {
        this.$refs['add-user'].show();
      });
    },

    reset() {
      this.$v.payload.$reset();
      this.payload = {
        id: '',
        name: '',
        email: '',
        password: '',
      };
    },

    async save() {
      this.$v.payload.$touch();

      if (!this.$v.payload.$invalid) {
        this.spinner = true;

        try {
          if (this.payload.id) {
            const { data } = await UserService.edit(this.payload);
            this.messageSuccess(`O usuário ${data.name} foi editado com sucesso`);
          } else {
            const { data } = await UserService.save(this.payload);
            this.messageSuccess(`O usuário ${data.name} foi cadastrado com sucesso`);
          }

          this.$nextTick(() => {
            this.$refs['add-user'].hide();
          });

          this.getAll();
        } catch (error) {
          console.log(error);
          this.messageError('Não foi possível realizar o cadastro, tente novamente ou entre contato com o administrador');
        }
      } else {
        this.messageError('Preencha os campos obrigatórios');
      }

      this.spinner = false;
    },
  },
  created() {
    this.getAll();
    this.getAgents();
  },
};
</script>
